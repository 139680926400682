import React, { createRef, useState } from 'react';
import {Accordion, AccordionBody, AccordionHeader, Card, CardBody, CardHeader, Button, Typography,Dialog, DialogHeader, DialogBody, DialogFooter, CardFooter,} from '@material-tailwind/react';
import dayjs from 'dayjs';
import IconAcc from 'components/accordion-icon';
import { Form, Formik } from 'formik';
import ContentWrapper from 'components/ui/content-wrapper';
import useSWR from 'swr';
import * as Yup from 'yup';
import { fetcherFileUpload, fetcherFull, fetcherSimple } from 'utils/api';
import FormikInputIcon from 'components/form/formik-input-icon';
import { toast } from 'react-toastify';
import FormikInput from 'components/form/formik-input';
import Image from 'components/image';
import UploadPicture from 'assets/img/uploadprofilepic.png';
import MaterialIcon from 'components/material-icon';
import UpdatePasswordDialog from 'components/dialog/update-password-dialog';
import UpdateDetailsDialog from 'components/dialog/update-details-dialog';

export default function Profile() {
    //AccordionControls
    const [showUpdatePasswordDialog, setShowUpdatePasswordDialog] = useState(false);
    const [showUpdateDetailsDialog, setShowUpdateDetailsDialog] = useState(false);
    const [open, setOpen] = useState(0);     
    const handleOpen = (value) => setOpen(open === value ? 0 : value);
    
    const [profilePicDeletable, setProfilePicDeletable] = useState(true);
    // timestamp value function with dayjs
    const [timestamp, setTimestamp] = useState(dayjs());

    // Refs
    const inputLogoFileRef = createRef(null);

    const {
        data: userData,
        error: UserDataError,
        mutate: userDataMutate,
        isValidating: userDataIsValidating,
    } = useSWR([`/v2/user`, 'GET'], ([path, method]) => fetcherSimple(path, method));

    const imageUrl = process.env.REACT_APP_API_SERVER + `/v2/user/picture`;

    const validationSchemaUser = Yup.object().shape({
        name: Yup.string().optional(),
        alt_email: Yup.string().optional(),
        phone: Yup.string().optional(),
        address_1: Yup.string().optional(),
        address_2: Yup.string().optional(),
        postcode: Yup.string().optional(),
        country: Yup.string().optional(),
        organisation: Yup.string().optional(),
        job_role: Yup.string().optional()
    });

    const validationSchemaPassword = Yup.object().shape({
        password: Yup.string().required('New Password is Required'),
        confirm_password: Yup.string().oneOf([Yup.ref('password')], "Passwords don't match").required('Confirm Password is required'),
    });

    const handleLogoUploadClick = () => {
        inputLogoFileRef.current?.click();
    };

    const profilePicUploadHandler = async (event) => {
        const csvSubmitToast = toast.loading('Saving Image...');
        if (!event.target.files) {
            toast.error('No file uploaded!');
            return;
        }
        const selectedFile = event.target.files[0];
        if (!selectedFile.type.includes('image/')) {
            toast.error('Only Image Files are supported. Please ensure that the file you are loading is not excessively large and has the correct extension and name.');
            return;
        }
        let imageResponse = undefined;
        try {
            imageResponse = await fetcherFileUpload(
                `/v2/user/picture`, 'POST', 'picture', selectedFile
            );
        } catch {
            const serverError = imageResponse || 'unknown issue in uploading image. Please try a different size or type.';
            toast.update(csvSubmitToast, {render: `Error uploading Image, ${serverError}.`, type: 'error', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
        }
        if (imageResponse && imageResponse?.status === 200) {
            toast.update(csvSubmitToast, {render: 'Profile Picture Uploaded', type: 'success', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            setTimestamp(dayjs());
            setProfilePicDeletable(true);
        } else {
            const serverError = imageResponse || 'unknown issue in uploading image. Please try a different size or type.';
            toast.update(csvSubmitToast, {render: `Error uploading Image, ${serverError}`, type: 'error', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            console.log(imageResponse.errors);
        }
    }

    const handleDeleteProfilePic = async () => {
        const deleteResponse = await fetcherSimple(
            `/v2/user/picture`, 'DELETE'
        );
        if (deleteResponse && deleteResponse.deleted) {
            toast.success('Profile Picture Deleted!');
            setProfilePicDeletable(false);
            setTimestamp(dayjs());
        } else if (deleteResponse && deleteResponse.error) {
            toast.error(`Error deleting picture: ${deleteResponse.message}`);
        } else {
            toast.error('Error deleting picture!')
        }
    }

    const handleUserSubmit = async (values) => {
        console.log(values)
        let sanitary_values = {};
        for (const property in values) {
            if ((values[property])) {
                sanitary_values[property] = values[property]
            }
        }
        console.log(sanitary_values)
        const userSubmitToast = toast.loading('Saving User...');
        let response = undefined;
        let serverError = undefined;
        try {
            response = await fetcherFull(`/v2/user`, 'PUT', sanitary_values);
        } catch {
            serverError = response || 'Unknown issue';
        }
        if (response?.success && response?.success === false) {
            toast.update(userSubmitToast, {render: `Error updating User, ${response.error || 'unknown error'}`, type: 'error', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            console.log(response.error);
        } else if (serverError) {
            toast.update(userSubmitToast, {render: `Error updating User, ${serverError || 'unknown error'}`, type: 'error', isLoading: false, autoClose: 5000, 
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
        } else {
            toast.update(userSubmitToast, {render: 'User Updated', type: 'success', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            userDataMutate(`/v2/user`);
        }
    }

    const handlePasswordSubmit = async (values) => {
        delete values.confirm_password;
        const userSubmitToast = toast.loading('Saving Password...');
        let response = undefined;
        let serverError = undefined;
        try {
            response = await fetcherFull(`/v2/user/password`, 'PUT', values);
        } catch {
            serverError = response || 'Unknown issue';
        }
        if (!values.password || values.password === '') {
            delete values.password;
        }
        if (response?.success && response?.success === false) {
            toast.update(userSubmitToast, {render: `Error updating Password, ${response.error || 'unknown error'}`, type: 'error', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            console.log(response.error);
        } else if (serverError) {
            toast.update(userSubmitToast, {render: `Error updating Password, ${serverError || 'unknown error'}`, type: 'error', isLoading: false, autoClose: 5000, 
                pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
        } else {
            toast.update(userSubmitToast, {render: 'Password Updated', type: 'success', isLoading: false, autoClose: 5000, 
            pauseOnFocusLoss: false, pauseOnHover: false, closeOnClick: true, closeButton: true});
            userDataMutate(`/v2/user`);
        }
    }

    return (
        <>
            <ContentWrapper>
            <div className='pt-6 py-2 mb-6'>
            <h1 className='md:text-5xl text-2xl font-display text-black py-2 md:px-6 px-2 rounded-full md:rounded-3xl 
                bg-white border-b-2 lg:border-b-2 border-blue-gray-500 w-fit mx-auto'>
                Profile
            </h1>
            </div>
            <div className=''>
                <div className='mt-4 mx-auto w-fit'>
                    <Card className='mx-2 mb-4 bg-white bg-opacity-20'
                            variant='gradient'>
                        <CardHeader className='w-full bg-blue-gray-500 text-white m-0 mb-2 rounded-t-xl 
                                    rounded-b-none px-2 py-1 text-xl font-display'
                                    color="transparent"
                                    floated={true}
                                    shadow={true}>
                                Details
                        </CardHeader>
                        <CardBody className='flex flex-col px-6 py-2 bg-white'>
                            <div className='flex flex-row'>
                                <div className='basis-2/3 w-full p-2 rounded-2xl bg-blue-gray-50'>
                                    <Typography className='font-display text-blue-gray-700'>Account Holder</Typography>
                                    <Typography className='font-body text-blue-gray-700'>
                                        <MaterialIcon name="person"/> {userData?.name || 'N/A'}<br />
                                        <MaterialIcon name="alternate_email"/> {userData?.alt_email || 'N/A'}<br />
                                        <MaterialIcon name="call"/> {userData?.phone || 'N/A'}<br />
                                    </Typography>
                                </div>
                                <div className='basis-1/3 w-full p-2 rounded-2xl ml-4 static'>
                                <Image alt="Profile PictureORGANISATION —" 
                                    key={timestamp}
                                    src={imageUrl}
                                    className="mt-6 rounded-lg border border-black"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src=`${UploadPicture}`;
                                    }} 
                                />
                                </div>
                            </div>
                            <div className='w-full p-2 mt-4 rounded-2xl bg-blue-gray-50 my-2'>
                                <Typography className='font-display text-blue-gray-700'>Organisation</Typography>
                                <Typography className='font-body text-blue-gray-700'>
                                    <MaterialIcon name="store"/> {userData?.organisation || 'N/A'}<br />
                                    <MaterialIcon name="badge"/> {userData?.job_role || 'N/A'}<br />
                                </Typography>
                            </div>
                            <div className='w-full p-2 rounded-2xl bg-blue-gray-50 my-2'>
                                <Typography className='font-display text-blue-gray-700'>Address</Typography>
                                <Typography className='font-body text-blue-gray-700'>
                                    <MaterialIcon name="contact_mail"/> {userData?.address_1 || 'N/A'}<br />
                                    <MaterialIcon name="contact_mail"/> {userData?.address_2 || 'N/A'}<br />
                                    <MaterialIcon name="contact_mail"/> {userData?.postcode || 'N/A'}<br />
                                    <MaterialIcon name="contact_mail"/> {userData?.country || 'N/A'}<br />
                                </Typography>
                            </div>
                        </CardBody>
                        <CardFooter className='text-center bg-blue-gray-300 rounded-b-lg'>
                        <Button size='lg' 
                                className='text-md rounded-full px-6 py-2 mr-4
                                font-body font-thin normal-case border border-blue-gray-700
                                hover:border-1 hover:border-blue-500 hover:text-blue-500'
                                color="white"      
                                onClick={() => setShowUpdateDetailsDialog(true)}>
                            Update Details
                        </Button>
                        <Button size='lg' 
                                className='text-md rounded-full px-6 py-2
                                font-body font-thin normal-case border border-blue-gray-700
                                hover:border-1 hover:border-blue-500 hover:text-blue-500'
                                color="white"      
                                onClick={() => setShowUpdatePasswordDialog(true)}>
                            Update Password
                        </Button>
                        </CardFooter>
                    </Card>
                </div>
                <div className='basis-1/3 rounded-xl min-h-[100px] mt-4'>
                </div>
            </div>
            </ContentWrapper>
            <UpdatePasswordDialog handlePasswordSubmit={handlePasswordSubmit} validationSchemaPassword={validationSchemaPassword}
                showUpdatePasswordDialog={showUpdatePasswordDialog} setShowUpdatePasswordDialog={setShowUpdatePasswordDialog}/>
            <UpdateDetailsDialog handleUserSubmit={handleUserSubmit} validationSchemaUser={validationSchemaUser}
                showUpdateDetailsDialog={showUpdateDetailsDialog} setShowUpdateDetailsDialog={setShowUpdateDetailsDialog}
                handleLogoUploadClick={handleLogoUploadClick} profilePicDeletable={profilePicDeletable} 
                handleDeleteProfilePic={handleDeleteProfilePic} profilePicUploadHandler={profilePicUploadHandler}
                inputLogoFileRef={inputLogoFileRef} timestamp={timestamp} imageUrl={imageUrl} UploadPicture={UploadPicture}
                setProfilePicDeletable={setProfilePicDeletable}/>
        </>
    );
}

import React from 'react';
import {
    Button, TabPanel,
    Card, Typography, Select, Option
} from '@material-tailwind/react';

export default function ModerationTab(
    { tabValue, surveyAbuse, setShowProcessDialog, setProcessingReport, surveyAbuseMutate, surveyAbuseError, moderationFilter, setModerationFilter, surveyRespondentAnswers, surveyRespondentAnswersError, modQShown, setModQShown, setConfirmingAnswer, setShowConfirmReportDialog }
) {

    return (
        <>
            <TabPanel value={tabValue}  className=''>
                {(surveyAbuse &&
                    <>
                        <Card className="p-2 min-h-[50vh] bg-white" shadow={true}>
                            <div className="py-2 flex justify-end">
                                <Button onClick={() => setModerationFilter('reports')} 
                                    color={moderationFilter === 'reports' ? 'white' : 'white'}
                                    className='
                                        rounded-full
                                        sm:px-4 sm:py-2
                                        text-sm
                                        px-2 py-1
                                        hover:border-1
                                        hover:border-blue-500
                                        hover:text-blue-500                                        
                                        border
                                        border-gray-500
                                        font-body font-thin normal-case
                                        mr-4
                                        mb-2'
                                    variant="filled">
                                    Abuse Reports
                                </Button>
                                <Button onClick={() => setModerationFilter('respondent_answers')}
                                    color={moderationFilter === 'respondent_answers' ? 'white' : 'white'}
                                    className='
                                    rounded-full
                                    sm:px-4 sm:py-2
                                    text-sm
                                    px-2 py-1
                                    hover:border-1
                                    hover:border-blue-500
                                    hover:text-blue-500
                                    border
                                    border-gray-500
                                    font-body font-thin normal-case
                                    mr-4
                                    mb-2'
                                    variant="filled">
                                    Respondent Answers
                                </Button>
                            </div>
                            {(moderationFilter === 'respondent_answers' &&
                                <Select label="Select a question to moderate" onChange={(e) => {
                                    const indexInt = parseInt(e);
                                    setModQShown(indexInt);
                                }}>
                                    {surveyRespondentAnswers.map((q, index) => (
                                        <Option key={q.id} value={`${index}`}>Question {q.position + 1}: {q.text}</Option>
                                    ))}
                                </Select>
                            )}
                            <table className='font-body md:table-fixed border-collapse text-blue-gray-700'>
                                {(moderationFilter === 'reports' &&
                                    <>
                                        <thead className='text-left text-sm sm:text-lg border-b border-gray-400'>
                                            <tr className=''>
                                                <th className='w-2/6 py-2'>Reported Response</th>
                                                <th className='w-1/12 sm:pr-6'>Status</th>
                                                <th className=''>Comment(s)</th>
                                                <th className='w-1/12'>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {surveyAbuse.abuse_reports.map((report, index) => (
                                                <tr className='border-b border-gray-400 odd:bg-gray-100' key={report.id}>
                                                    <td className='text-sm pl-1'>{report.answer_text}</td>
                                                    <td className='text-sm sm:pr-6'>{report.status}</td>
                                                    <td className='text-sm'>
                                                        <Typography className='text-sm font-bold text-amber-900'>
                                                            User: </Typography>{report.user_comment || 'N/A'}<br />
                                                        <Typography className='text-sm font-bold text-red-900'>
                                                            Admin: </Typography>{report.admin_comment || 'N/A'}
                                                    </td>
                                                    <td className='text-right md:pr-4'>
                                                        <Button type="button"
                                                            variant="filled"
                                                            color='white'
                                                            className="
                                                                rounded-full 
                                                                text-sm
                                                                sm:px-4 sm:py-1
                                                                px-2 py-1
                                                                border
                                                                border-gray-500
                                                                hover:border-1
                                                                hover:border-blue-500
                                                                hover:text-blue-500
                                                                font-body font-thin normal-case"
                                                            onClick={() => {
                                                                setProcessingReport(report);
                                                                setShowProcessDialog(true);
                                                            }}>
                                                            Edit
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                            )}
                                        </tbody>
                                    </>
                                )}
                                {(moderationFilter === 'respondent_answers' &&
                                    <>
                                        <thead className='text-left text-lg border-b border-gray-500'>
                                            <tr className=''>
                                                <th className='pt-4 w-5/6 pl-1'>Response Text</th>
                                                <th className='pt-4 w-1/12'>Hidden</th>
                                                <th className='w-1/12'>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {surveyRespondentAnswers?.[modQShown]?.respondent_answers.map((answer, index) => (
                                                <tr className='border-b border-gray-400 odd:bg-gray-100' key={answer.id}>
                                                    <td className=''>{answer.text}</td>
                                                    <td>{answer.hidden ? 'Yes' : 'No'}</td>
                                                    <td className='text-right md:pr-2 py-1'>
                                                        <Button type="button"
                                                            variant="filled"
                                                            color="white"
                                                            className="
                                                                rounded-full 
                                                                text-sm 
                                                                px-2 py-0
                                                                hover:border-1
                                                                hover:border-blue-500
                                                                hover:text-blue-500
                                                                border
                                                                border-gray-500
                                                                font-body font-thin normal-case"
                                                            onClick={() => {
                                                                setConfirmingAnswer(answer);
                                                                setShowConfirmReportDialog(true);
                                                            }}>
                                                            Report
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                            )}
                                        </tbody>
                                    </>
                                )}
                            </table>
                        </Card>
                    </>
                )}
                {(surveyAbuseError &&
                    <Typography>Error loading survey abuse reports</Typography>
                )}
            </TabPanel>
        </>
    );
}

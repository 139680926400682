import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useSWR from 'swr';
import {Form, Formik} from 'formik';
import FormikInput from 'components/form/formik-input';
import FormikTextarea from 'components/form/formik-textarea';
import * as Yup from 'yup';
import {fetcherFull, fetcherSimple} from 'utils/api';
import ContentWrapper from '../../components/ui/content-wrapper';
import {Button, Card, CardBody,  Dialog, DialogHeader, DialogBody, DialogFooter,
    IconButton, Tooltip, CardHeader, CardFooter, Select, Option} from '@material-tailwind/react';
import MaterialIcon from 'components/material-icon';
import { toast } from 'react-toastify';

export default function ListSurvey() {
    const navigate = useNavigate();
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showCloneDialog, setShowCloneDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [surveyToDelete, setSurveyToDelete] = useState(undefined);
    const [surveyToCopy, setSurveyToCopy] = useState(undefined);
    const [stateToView, setStateToView] = useState('');
    const [listPage, setListPage] = useState(1);
    const {data, error, mutate: listMutate} = useSWR(
        [(stateToView ? `/v2/survey?state=${stateToView}&page=${listPage}` : `/v2/survey?page=${listPage}`), 'GET'], 
        ([path, method]) => fetcherSimple(path, method)
    );

    const handleSubmit = async (values) => {
        const response = await fetcherFull('/v2/survey', 'POST', values);
        if (response.name) {
            toast.success('Your new survey has been created!');
            listMutate('/v2/survey');
            navigate(`/survey/${response.id}`);
        } else {
            toast.error('There has been an error creating this survey. If the problem persists please contact Gobby support.');
        }
    };

    const handleCloneDialogOpen = async (surveyId) => {
        setSurveyToCopy(surveyId);
        setShowCloneDialog(true);
    };

    const handleCloneDialogClose = () => {
        setSurveyToCopy(undefined);
        setShowCloneDialog(false);
    };

    const handleCloneSurvey = async () => {
        setShowCloneDialog(false);
        const surveyIdCopy = surveyToCopy;
        setSurveyToCopy(undefined);
        const response = await fetcherSimple(`/v2/survey/${surveyIdCopy}/clone`, 'POST');
        if (response && response?.new_survey_id) {
            toast.success('The survey has been cloned!');
            listMutate('/v2/survey');
            window.open(`/survey/${response.new_survey_id}`, "_blank");
        } else {
            toast.error('There has been an error cloning this survey. Please try again, if the problem persists then contact Gobby support.');
        }
    };

    const handleDeleteDialogOpen = async (surveyId) => {
        setSurveyToDelete(surveyId);
        setShowDeleteDialog(true);
    };

    const handleDeleteDialogClose = () => {
        setSurveyToDelete(undefined);
        setShowDeleteDialog(false);
    };

    const handleDeleteSurvey = async () => {
        setShowDeleteDialog(false);
        const survey_id_deleting = surveyToDelete;
        setSurveyToDelete(undefined);
        if (survey_id_deleting && typeof survey_id_deleting === 'number') {
            const response = await fetcherSimple(`/v2/survey/${survey_id_deleting}`, 'DELETE');
            if (response.success) {
                listMutate({...data, data: [...data.data.filter(d => d.id !== survey_id_deleting)]});
                toast.success('The survey has been permanently deleted!');
            } else {
                toast.error('There has been an error deleting this survey. Please try again, if the problem persists then contact Gobby support.');
            }
        } else {
            toast.error('We could find no survey by that name to delete!');
        }
    };

    const initialValues = {
        name: '',
        description: '',
        is_allow_anonymous: false,
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Survey Name Required'),
        description: Yup.string().required('Survey Description Required')
    });

    const previousPage = () => {
        const previousPage = listPage;
        setListPage(previousPage - 1);
    }

    const nextPage = () => {
        const previousPage = listPage;
        setListPage(previousPage + 1);
    }

    return (
        <>
            <ContentWrapper>
                <div className='pt-6 py-2'>
                <h1 className='md:text-5xl text-2xl font-display text-black py-2 md:px-6 px-2 rounded-xl md:rounded-3xl 
                bg-white border-b-2 lg:border-b-2 border-blue-gray-500 w-fit mx-auto'>
                    Survey Administration
                </h1>
                </div>
                <div className='rounded-xl my-4 bg-white px-4 md:px-10 md:pb-8 pb-4 shadow'>
                    <div className='grid place-content-center'>
                        <Button size='lg' 
                            className='text-lg rounded-full px-6 py-2 mb-4
                            font-body font-thin normal-case border border-black
                            hover:border-1 hover:border-blue-500 hover:text-blue-500 hover:bg-white md:mt-4'
                            color="blue"      
                            onClick={() => setShowCreateDialog(true)}>
                            Create New Survey
                        </Button>
                    </div>
                    <div className="flex flex-wrap justify-between">                      
                        <div className=''>
                            <Select value="" className='bg-blue-gray-50' label="Filter Surveys" onChange={(e) => {
                                    const state = e;
                                    setStateToView(state);
                                }}>
                                <Option value="">All</Option>
                                <Option value="draft">Draft</Option>
                                <Option value="published">Published</Option>
                                <Option value="active">Active</Option>
                                <Option value="paused">Paused</Option>
                                <Option value="finished">Finished</Option>
                                <Option value="archive">Archive</Option>
                            </Select>
                        </div>
                            <div className=''>
                        {data && data.pager && 
                            <div className='flex justify-end'>
                                <IconButton color="black" type="button" variant="filled"
                                    disabled={listPage === 1} onClick={previousPage}>
                                    <MaterialIcon name="arrow_back_ios_new" size="xl"/>
                                </IconButton>
                                <IconButton color="black" type="button" variant="filled" className='ml-2'
                                disabled={data.pager.current_page === data.pager.last_page} onClick={nextPage}>
                                    <MaterialIcon name="arrow_forward_ios" size="xl"/>
                                </IconButton>
                            </div>
                        }

                            </div>
                        </div>
                </div>                
                <div className="">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                        {data && data.data && data.data.filter(s => (stateToView ? (s?.state === stateToView) : s)).map((survey =>
                            <Card key={survey.id} className="bg-blue-gray-100 rounded-xl border border-blue-gray-700 p-0">
                                <CardHeader 
                                    floated={false}
                                    shadow={false}
                                    className="w-full bg-blue-gray-500 text-white m-0 rounded-t-xl 
                                    rounded-b-none px-2 py-1 text-md font-display flex justify-left">
                                    {survey.name}
                                </CardHeader>
                                <CardBody className='font-body sm:min-h-[90px] text-black px-2 py-1 bg-white'>
                                    {survey.description}
                                </CardBody>
                                <CardFooter className='flex flex-row-reverse pt-2 pr-2 pb-2 gap-2 border-t border-gray-500 rounded-b-xl'>
                                    <Tooltip 
                                    className="font-body" 
                                    content="Delete the survey dialogue box!"
                                                animate={{
                                                    mount: { scale: 1, y: 10 },
                                                    unmount: { scale: 1, y: 25 },
                                            }}>
                                        <IconButton color="red" variant="gradient" size="sm"
                                                onClick={() => handleDeleteDialogOpen(survey.id)}>
                                            <MaterialIcon size="lg" name="delete"/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip 
                                    className="font-body" 
                                    content="Clone the survey"
                                                animate={{
                                                    mount: { scale: 1, y: 10 },
                                                    unmount: { scale: 1, y: 25 },
                                            }}>
                                        <IconButton color="amber" variant="gradient" size="sm"
                                                onClick={() => handleCloneDialogOpen(survey.id)}>
                                            <MaterialIcon size="lg" name="content_copy"/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip 
                                    className="font-body" 
                                    content="Enter the survey editing screen"
                                            animate={{
                                                mount: { scale: 1, y: 10 },
                                                unmount: { scale: 1, y: 25 },
                                            }}>
                                        <IconButton variant='gradient' color="blue" size="sm"
                                            onClick={() => navigate(`/survey/${survey.id}`)}>
                                                <MaterialIcon size="lg" name="edit" />
                                        </IconButton>
                                    </Tooltip>
                                </CardFooter>
                            </Card>
                        ))}
                        {( error &&
                            <Card>
                                <CardBody>
                                    Error loading data! 
                                </CardBody>
                            </Card>
                        )}
                    </div>
                </div>


            </ContentWrapper>
            <Dialog open={showCreateDialog} data-dialog="dialog-xl" handler={() => setShowCreateDialog(showCreateDialog=>!showCreateDialog)} className='min-w-fit'>
                <DialogHeader className='bg-gray-300 text-black font-display rounded-t-lg'>
                    Create A New Survey
                </DialogHeader>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    {({isValid, dirty}) => (
                        <Form>
                            <DialogBody className='font-body overflow-y-auto max-h-[80vh]'>
                                <div className="mb-6 px-2 bg-bb font-body">
                                    <FormikInput className='font-body' name="name" type="text" color="indigo"
                                        label="Survey Name" required/>
                                </div>
                                <div className="px-2 mb-6">
                                    <FormikTextarea className='font-body' name="description" type="text" color="indigo"
                                        label="Survey Description" required/>
                                </div>
                            </DialogBody>
                            <DialogFooter>
                                <div className='absolute bottom-4 right-4'>
                                    <Button type="button"  
                                    className='mr-4 hover:bg-red-900 px-6 py-2 hover:text-white hover:border-red-900 border border-gray-500 rounded-full' 
                                    variant="gradient"
                                    color='white'
                                    size="lg" 
                                        onClick={() => setShowCreateDialog(false)}>
                                        Cancel
                                    </Button>
                                    <Button type="submit"  
                                            disabled={!(isValid && dirty)} 
                                            onClick={() => setShowCreateDialog(false)}
                                            className="
                                            rounded-full
                                            px-6 py-2 
                                            hover:border-1 hover:border-blue-500 hover:text-blue-500
                                            border
                                            border-gray-500"
                                            variant="gradient"
                                            color="white"
                                            size="lg">
                                        Create
                                    </Button>
                                </div>
                            </DialogFooter>
                        </Form>
                    )}
                </Formik>
            </Dialog>
            <Dialog open={showCloneDialog} handler={() => handleCloneDialogClose()}>
                <DialogHeader className='bg-gray-300 text-black font-display rounded-t-lg'>
                    Clone Survey?
                </DialogHeader>
                <DialogBody className='mb-6 font-body overflow-y-auto max-h-[80vh]'>
                    This will create a copy of the survey.
                </DialogBody>
                <DialogFooter>
                    <div className='absolute bottom-4 right-4'>
                        <Button type="button" 
                                className="
                                mr-4
                                rounded-full 
                                px-6 py-2
                                hover:bg-red-900
                                hover:text-white
                                hover:border-red-900
                                border
                                border-gray-500"
                                color='white'
                                variant="gradient"
                                size="lg"
                            onClick={() => handleCloneDialogClose()}>
                            Cancel
                        </Button>
                        <Button type="submit"
                                    className="
                                    rounded-full 
                                    hover:border-1
                                    hover:border-blue-500
                                    hover:text-blue-500
                                    border
                                    border-gray-500
                                    px-6 py-2 "
                                    variant="gradient"
                                    color='white'
                                    size="lg"
                                onClick={() => handleCloneSurvey()}>
                            Clone
                        </Button>
                    </div>
                </DialogFooter>
            </Dialog>
            <Dialog open={showDeleteDialog} handler={() => handleDeleteDialogClose()}>
                <DialogHeader className='bg-gray-300 text-black font-display rounded-t-lg'>
                    Delete Survey?
                </DialogHeader>
                <DialogBody className='mb-6 font-body overflow-y-auto max-h-[80vh]'>
                    Are you sure you want to do this? This will permanently delete the survey and all data from the system.
                </DialogBody>
                <DialogFooter>
                    <div className='absolute bottom-4 right-4'>
                        <Button type="button" 
                                size="lg"
                                color='white'
                                className='rounded-full px-6 py-2 mr-4 
                                            hover:border-1
                                            hover:border-blue-500
                                            hover:text-blue-500
                                            border
                                            border-gray-500 
                                            font-body' variant="filled" 
                            onClick={() => handleDeleteDialogClose()}>
                            Cancel
                        </Button>
                        <Button type="submit"
                                size="lg"
                                color="white"
                                className='rounded-full px-6 py-2 mr-2 
                                hover:bg-red-500 hover:text-white hover:border-red-900
                                border border-gray-500 
                                font-body' variant="filled"  
                                onClick={() => handleDeleteSurvey()}>
                            Delete
                        </Button>
                    </div>
                </DialogFooter>
            </Dialog>
        </>
    );
}
